import 'nprogress/nprogress.css';
import '../styles/globals.scss';

import useTranslation from '@/hooks/useTranslation';
import { isClientSide } from '@/lib/client-server';
import { startNProgress, storePathValues } from '@/lib/route';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { ReactElement, useEffect } from 'react';

function App({ Component, pageProps }: AppProps): ReactElement {
  startNProgress();
  const router = useRouter();
  const { currentLocale } = useTranslation();
  useEffect(() => {
    if (isClientSide()) {
      document.documentElement.lang = currentLocale;
    }
  }, [currentLocale]);
  useEffect(() => {
    storePathValues();
  }, [router.asPath]);

  return (<Component {...pageProps} />);
}

export default App;
