import Router from 'next/router';
import NProgress from 'nprogress';

export function storePathValues(): void {
  if (!sessionStorage) return;
  // Set the previous path as the value of the current path.
  const prevPath = sessionStorage.getItem('currentPath') || '';
  sessionStorage.setItem('prevPath', prevPath);
  // Set the current path value by looking at the browser's location object.
  sessionStorage.setItem('currentPath', window.location.pathname + window.location.search);
}

export function startNProgress(): void {
  const { events } = Router;
  events.on('routeChangeStart', () => {
    NProgress.start();
  });
  events.on('routeChangeComplete', () => NProgress.done());
  events.on('routeChangeError', () => NProgress.done());
}

export default {};
