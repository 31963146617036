import { isServerSide } from './client-server';

export function getCookie(cname: string): string {
  if (isServerSide()) return '';

  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
}

interface SetCookieOptions {
  key: string
  value: string
  days?: number
}

export function setCookie({ key, value, days = 7 }: SetCookieOptions): void {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  document.cookie = `${key}=${value}; expires=${date.toUTCString()}; path=/`;
}

interface DeleteCookieOptions {
  name: string
  path?: string
  domain?: string
}

export function deleteCookie({ name, path, domain }: DeleteCookieOptions): void {
  if (getCookie(name)) {
    document.cookie = `${name}=${(path) ? `;path=${path}` : ''
    }${(domain) ? `;domain=${domain}` : ''
    };expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
}

export default {};
