import nestedProperty from 'nested-property';

import { getCookie, setCookie } from '@/lib/cookie';
import { isServerSide } from '@/lib/client-server';
import pt_BR from '../public/locales/pt-BR.json';
import en_US from '../public/locales/en-US.json';

interface Options extends Record<string, string | number | undefined> {
  count?: number
}

interface SupportedLocaces {
  [key: string]: any
}

const supportedLocales: SupportedLocaces = {
  'pt-BR': pt_BR,
  'en-US': en_US,
};

const DEFAULT_LOCALE = 'pt-BR';
const LOCALE_KEY = 'hub-locale';

const tryNavigatorLanguages = (): string => {
  if (isServerSide()) return DEFAULT_LOCALE;

  const supported = Object.keys(supportedLocales);
  let found: string | null = null;
  const { languages } = navigator;

  languages.forEach((language) => {
    if (found) return;
    if (supported.includes(language)) found = language;
  });

  if (found) return found;

  return DEFAULT_LOCALE;
};

const getCurrentLocale = (): string => getCookie(LOCALE_KEY) || tryNavigatorLanguages();

interface IUseTranslation {
  t: (prop: string, options?: Options) => string
  currentLocale: string
  setLocale: (locale: string) => void
}

export default function useTranslation(): IUseTranslation {
  const translations = supportedLocales[getCurrentLocale()];

  return {
    t: (prop: string, options?: Options) => {
      let value = nestedProperty.get(translations, prop) || prop;

      if (options) {
        const { count } = options;

        if (count) {
          const newProp = prop + (count > 1 ? '_other' : '_one');
          value = nestedProperty.get(translations, newProp) || newProp;
        }

        const optionsKeys = Object.keys(options).filter((key) => key !== 'count');

        if (optionsKeys.length) {
          optionsKeys.forEach((key) => {
            const regex = new RegExp(`{{${key}}}`, 'ig');
            value = value.replace(regex, options[key]);
          });
        }
      }

      return value;
    },
    currentLocale: getCurrentLocale(),
    setLocale: (locale = 'pt-BR'): void => setCookie({
      key: LOCALE_KEY,
      value: locale,
      days: 9999,
    }),
  };
}
